exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-common-question-dropdown-index-tsx": () => import("./../../../src/pages/CommonQuestion/Dropdown/index.tsx" /* webpackChunkName: "component---src-pages-common-question-dropdown-index-tsx" */),
  "component---src-pages-common-question-index-tsx": () => import("./../../../src/pages/CommonQuestion/index.tsx" /* webpackChunkName: "component---src-pages-common-question-index-tsx" */),
  "component---src-pages-evidences-carousel-index-tsx": () => import("./../../../src/pages/Evidences/Carousel/index.tsx" /* webpackChunkName: "component---src-pages-evidences-carousel-index-tsx" */),
  "component---src-pages-evidences-index-tsx": () => import("./../../../src/pages/Evidences/index.tsx" /* webpackChunkName: "component---src-pages-evidences-index-tsx" */),
  "component---src-pages-google-reviews-index-tsx": () => import("./../../../src/pages/GoogleReviews/index.tsx" /* webpackChunkName: "component---src-pages-google-reviews-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/Home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/Services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-who-are-we-index-tsx": () => import("./../../../src/pages/WhoAreWe/index.tsx" /* webpackChunkName: "component---src-pages-who-are-we-index-tsx" */)
}

